<template>
    <div class="step-container">
        <div v-for="(label, index) in steps.labels" :key="index" class="flex">
            <div
                :class="{ 'current-step': index + 1 === steps.current }"
                class="step-item"
            >
                <div class="step-icon">{{ index + 1 }}</div>
                <div class="step-content">
                    <div class="step-label" v-text="label" />
                    <slot :name="index" />
                </div>
            </div>
            <div v-if="index + 1 < steps.labels.length" class="dash" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Step',
    props: {
        steps: {
            type: Object,
            default: function () {
                return {
                    current: 1,

                    labels: []
                }
            }
        }
    },
    methods: {
        addStepClass: function (index) {
            return index === 0 ? 'info-icon' : ''
        }
    },

    data() {
        return {
            showPksTime: false
        }
    }
}
</script>

<style lang="scss" scoped>
.step-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
}

.step-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 32px;
    cursor: default;

    .flex {
        align-items: flex-end;
        .info-icon {
            cursor: pointer;
            width: 12px;
            height: 12px;
        }
    }

    .step-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-bottom: 5px;
        border: $primary-blue 2px solid;
        border-radius: 32px;
        box-sizing: border-box;
        color: $primary-blue;
        text-align: center;
    }

    .step-content {
        display: flex;
        flex-wrap: wrap;
    }

    .step-label {
        font-size: 14px;
        letter-spacing: 0.4px;
        width: 150px;
        color: $secondary-grey;
        word-break: keep-all;
        text-align: center;

        @media screen and (max-width: 575px) {
            width: 80px;
            word-break: break-word;
        }
    }

    &.current-step {
        .step-icon {
            background-color: $primary-blue;
            color: $primary-white;
        }
        .step-label {
            color: $primary-blue;
        }
    }
}

.dash {
    width: 74px;
    height: 2px;
    background-color: $primary-blue;
    margin: 15px 3px;
    @media screen and (max-width: 575px) {
        width: 40px;
    }
}
</style>
