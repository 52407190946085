<template>
    <div class="toolbox-container">
        <div class="toolbox-button" :class="{ disabled }" @click="toggle()">
            <div v-text="toolName" />
            <div class="dropdown-triangle" />
        </div>
        <div v-show="isShowToolbox" class="toolbox">
            <slot name="tools" :toggle="toggle" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Toolbox',
    props: {
        toolName: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggle: function () {
            if (this.disabled) return
            this.isShowToolbox = !this.isShowToolbox
        },
        clickBlankHandler: function (e) {
            if (!this.$el.contains(e.target)) {
                this.isShowToolbox = false
            }
        }
    },
    data() {
        return {
            isShowToolbox: false
        }
    },
    mounted() {
        document.addEventListener('click', this.clickBlankHandler)
    },
    unmounted() {
        document.removeEventListener('click', this.clickBlankHandler)
    }
}
</script>

<style lang="scss" scoped>
.toolbox-container {
    position: relative;
    width: 160px;
    color: $fourth-black;
    font-size: 15px;
    z-index: 5;

    .toolbox-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
        color: $fourth-black;
        font: 14px;

        .dropdown-triangle {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }
    .toolbox {
        position: absolute;
        width: 100%;
        background-color: $primary-white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
        margin-top: 5px;

        & > :deep(div) {
            &:hover {
                background: $seventh-grey;
            }
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            cursor: pointer;
            user-select: none;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
    }
}
</style>
